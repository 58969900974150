

















































































































import useUser from "@/use/user";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  components: {
    OVehicleMileage: () =>
      import("@/components/organisms/o-vehicle-mileage.vue"),
    ODamageModal: () => import("@/components/organisms/o-damage-modal.vue"),
  },
  setup(_, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root });

    const state = reactive({
      onboarding: 0,
      items: [],
      total: 0,
      loading: false,
      loaded: false,
      contactTimer: null as any,
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("vehicle", { params: { page: 1, itemsPerPage: -1 } })
        .then(({ data: { vehicles, total } }) => {
          state.items = vehicles.map((vehicle: any, index: number) => ({
            id: index,
            type: "",
            name: "",
            registration: "",
            mileage: "",
            oilChange: "",
            actions: null,
            ...vehicle,
          }));
          state.total = total;
        })
        .catch(() => {
          console.log("error");
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const prev = () =>
      (state.onboarding =
        state.onboarding - 1 < 0 ? state.total - 1 : state.onboarding - 1);
    const next = () =>
      (state.onboarding =
        state.onboarding + 1 === state.total ? 0 : state.onboarding + 1);

    return {
      hasAccessTo,
      hasManyCompanies,
      fetchData,
      state,
      prev,
      next,
      encodeURIComponent,
    };
  },
});
